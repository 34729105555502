import React, { Component } from "react";
import { createBrowserHistory } from "history";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import LoaderComponent from "./components/Loader";
import RouterComponent from "./components/Router";
import Cookie from "../assets/js/utils/Cookie";
import Api from "../assets/js/utils/Api";
import { helper } from "../assets/js/utils/Element";
import AuthApi from '../assets/js/utils/Auth';
// import NewUpdate from "./components/NewUpdate";
// import GpdrBanner from "./components/GpdrBanner";
// import Debug from "../views/components/Debug";
// import ScrollTop from "./components/ScrollTop";
import SplashScreen from "./components/SplashScreen";
import { styled } from '@mui/material/styles';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Config from "../../Config";
import { mapStateToProps } from "../store/reducers/rootReducer";
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
// import InactiveUserModal from "./components/Modals/InactiveUserModal";
import "../assets/fonts/gotham/all.css";
import "../assets/scss/App.scss";
import {
    primaryColor,
    grayColor,
    whiteColor,
    infoColor,
} from "../assets/jss/material-kit-pro-react.jsx";
import { appStyle, classes } from "../assets/jss/appStyle.jsx";
const AppRoot = styled('div')(({ theme }) => appStyle(theme));

const history = createBrowserHistory();
history.listen(function (location) {
    setTimeout(function () {
        // window.gtag("pageTitle", document.title);
        // window.gtag("event", "page_view", {
        //     page_path: window.location.href,
        // });

        window.fbq("track", "PageView");
    }, 1000);

    var requestData = [];
    requestData.push({
        activityType: "viewed_page",
        url: window.location.href,
        data: Cookie.getAdsData(),
    });

    Api.sendActivity(requestData);
    Api.sendAdClickedActivity();
    if (
        process.env.REACT_APP_DEBUG === true ||
        process.env.REACT_APP_DEBUG === "true"
    ) {
        console.log("React-Router: " + location.pathname);
    }
    window.scrollTo(0, 0);
});

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
        this.loading = this.props.loading;
        const hasAccessToken = AuthApi.hasAccessToken();
        if(hasAccessToken === false){
            this.loading = false;
        }
    }
    componentDidMount() {
        const that = this;
        const { dispatch } = this.props;
        this.loadSettings();
        const hasAccessToken = AuthApi.hasAccessToken();

        AuthApi.validateToken().then((isValidToken) => {
            this.loading = false;
            if(hasAccessToken === true && isValidToken === false){
                that.logout();
            }else if(hasAccessToken === true){
                that.handleUserInfo(isValidToken,false);
                AuthApi.idleRefreshToken();
            }
        }).catch(err => {
            this.loading = false;
            dispatch({type: 'UPDATE_STATE',state: {}});
            console.log(err);
        });

        if(process.env.REACT_APP_ENV !== "production"){
            console.log(process.env);
        }

        let returnUrl = helper.getParam("rte");
        if (returnUrl && returnUrl.length > 0 && returnUrl !== "null") {
            returnUrl = window.location.search.replace("?rte=", "");
            dispatch({ type: "UPDATE_STATE", state: { url: "/" + returnUrl } });
            history.push(returnUrl);
            this.listenHistory();
        } else {
            //View page activity request
            if (window.location.pathname !== "/" || true) {
                var requestData = [];
                requestData.push({
                    activityType: "viewed_page",
                    url: window.location.href,
                    data: Cookie.getAdsData(),
                });
                Api.sendActivity(requestData);
                Api.sendAdClickedActivity();
            }
            this.listenHistory();
            this.sendGooglePageView();
        }
    }
    handleUserInfo(data,login = false){
        const that = this;
        if(data.numberOfResponses > 0){
            data.response.map(user => {
                let actionType = 'UPDATE_STATE';
                if(login && user.hasOwnProperty("cognitoForcePasswordReset") && user.cognitoForcePasswordReset && user.username === user.userId){
                    history.push('/user/onboarding');
                    actionType = 'LOGIN';
                }else{
                    if(login){
                        if(user.username === user.userId){
                            history.push('/user/onboarding');
                        }else{
                            history.push('/dashboard');
                        }
                        actionType = 'LOGIN';
                    }
                }
                user = Api.prepareMemberObject(user);
                var fullName = "";
                if(user.hasOwnProperty("firstName")){
                    fullName = user.firstName;
                }
                if(user.hasOwnProperty("lastName")){
                    fullName += " "+user.lastName;
                }

                try{
                    if('FS' in window){
                        window.FS.identify(user.userId, {
                            displayName: fullName.trim(),
                            email: user.userEmail,
                            userId: user.userId
                        });
                    }
                }catch(e){  }

                that.props.dispatch({
                    type: actionType,
                    state: {
                        authorized: true,
                        user: user
                    }
                });
                
                return null;
            });
        }
    }
    logout(){
        const { dispatch } = this.props;
        history.push("/logout");
        AuthApi.logout();
        dispatch({type: 'LOGOUT'});
    }
    listenHistory() {
        const { dispatch } = this.props;
        history.listen(function (update) {
            if (update.location.hash.length <= 0) {
                dispatch({
                    type: "UPDATE_STATE",
                    state: { url: update.location.pathname },
                });
            }
        });
    }
    sendGooglePageView() {
        try {
            setTimeout(function () {
                // window.gtag("pageTitle", document.title);
                // window.gtag("event", "page_view", {
                //     page_path: window.location.href,
                // });

                window.fbq("track", "PageView");
            }, 1000);
        } catch (e) {
            console.log(e);
        }
    }
    getUserTheme() {
        const buttonText = whiteColor;
        const iconColor = whiteColor;
        let themeOptions = {
            palette: {
                primary: {
                    main: primaryColor[0],
                    light: grayColor[5],
                    dark: grayColor[20],
                    contrastText: whiteColor,
                },
                secondary: {
                    main: infoColor[0],
                    light: infoColor[6],
                    dark: infoColor[0],
                    contrastText: whiteColor,
                },
                custom: {
                    main: primaryColor[0],
                    buttonText: buttonText,
                    iconColor: iconColor,
                },
            },
            typography: {
                fontFamily: [
                    '"Gotham-Book"',
                    '"Helvetica Neue"',
                    "Arial",
                    "sans-serif",
                ].join(","),
            },
            customize: {
                fontSize: "14px",
            },
        };
        const muiTheme = createTheme(themeOptions);
        return muiTheme;
    }
    getHeadScripts() {
        const organizationId = Config.getFullStoryId();
        let headScripts = [];
        headScripts.push({
            type: "text/javascript",
            innerHTML:
                "window['_fs_debug'] = false;" +
                "window['_fs_host'] = 'fullstory.com';" +
                "window['_fs_script'] = 'edge.fullstory.com/s/fs.js';" +
                "window['_fs_org'] = '" +
                organizationId +
                "';" +
                "window['_fs_namespace'] = 'FS';" +
                "(function(m,n,e,t,l,o,g,y){" +
                "if (e in m) {if(m.console && m.console.log) { /*m.console.log('FullStory namespace conflict. Please set window[\"_fs_namespace\"].');*/} return;}" +
                "g=m[e]=function(a,b,s){g.q?g.q.push([a,b,s]):g._api(a,b,s);};g.q=[];" +
                "g.identify=function(i,v,s){g(l,{uid:i},s);if(v)g(l,v,s)};g.setUserVars=function(v,s){g(l,v,s)};g.event=function(i,v,s){g('event',{n:i,p:v},s)};" +
                "g.shutdown=function(){g('rec',!1)};g.restart=function(){g('rec',!0)};" +
                "g.log = function(a,b) { g('log', [a,b]) };" +
                "g.consent=function(a){g('consent',!arguments.length||a)};" +
                "g.identifyAccount=function(i,v){o='account';v=v||{};v.acctId=i;g(o,v)};" +
                "g.clearUserCookie=function(){};" +
                "})(window,document,window['_fs_namespace'],'script','user');",
        });
        headScripts.push({
            type: "text/javascript",
            src: "https://edge.fullstory.com/s/fs.js",
            async: 1,
            crossOrigin: "anonymous",
        });
        return headScripts;
    }
    loadSettings(){
        this.setState({
            loading: true
        });
        Api.getSettings(true).then(data => {
            const settings = data['mogli-app-react'];
            Config.populateConfig(settings);
            this.setState({
                loading: false
            });
        }).catch(err => {
            this.setState({
                loading: false
            });
        });
    }
    onInactiveUserModalClose(){
        AuthApi.logout();
        this.props.dispatch({type: 'LOGOUT'});
        history.push("/");
    }
    getStyles(){
        let styles = [];
        return styles;
    }
    render() {
        const { mainLoading, authorized, url } = this.props.state;
        const innerTheme = this.getUserTheme();
        if (
            process.env.REACT_APP_DEBUG === true ||
            process.env.REACT_APP_DEBUG === "true"
        ) {
            console.log(this.props.state);
        }
        if (mainLoading && Config.getAppThemeType() === "mui") {
            return (
                <ThemeProvider theme={innerTheme}>
                    <LoaderComponent />
                </ThemeProvider>
            )
        }

        const isTransparentHeader = helper.isTransparentHeader(url);
        const bodyClass = helper.getBodyClass(url);
        const headScripts = this.getHeadScripts();

        if ((this.loading === true || this.state.loading) && Config.getAppThemeType() === "mui") {
            return <SplashScreen />;
        }
        return (
            <IconSettings iconPath="/assets/icons">
                <BrowserRouter history={history} location={history.location}>
                    <Helmet
                        htmlAttributes={{
                            class: bodyClass,
                        }}
                        script={headScripts}
                        link={this.getStyles()}
                        title={Config.getAppTitle()}
                        keywords={Config.getKeywords()}
                    >
                        <meta name="description" content={Config.getAppDescription()} />
                    </Helmet>
                    <AppRoot
                        className={
                            classes.root +
                            " " +
                            bodyClass +
                            " App" +
                            (authorized ? " user_authorized" : "") +
                            (isTransparentHeader ? " app_transparent_header" : "")
                        }
                    >
                        <ThemeProvider theme={innerTheme}>
                            <div className="main-container" id="global_wrapper">
                                <RouterComponent history={history} />
                            </div>
                        </ThemeProvider>
                    </AppRoot>
                </BrowserRouter>
            </IconSettings>
        );
    }
}

App.defaultProps = {
    loading: true
}
App.propTypes = {
    loading: PropTypes.bool,
}

export default connect(mapStateToProps)(App);
