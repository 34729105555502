import Config from '../../../../Config';
import Cookie from './Cookie';
import { helper } from './Element'
import axios from 'axios';

const ApiHelper = {
    countries: [],
    countryStates: [],
    getDefaultMemberObject(){
        return  {
            firstName: "",
            isSoftDeleted: false,
            lastName: "",
            userEmail: "",
            userId: "",
            BusinessAdmin__c: false,
            SuperAdmin__c: false,
            Beta__c: false,
        };
    },
    prepareMemberObject(member){
        const defaultObject = this.getDefaultMemberObject();
        let memberObject = Object.assign({}, defaultObject); //Create copy to avoid reference copy
        memberObject = Object.assign(memberObject,member);
        if(member.hasOwnProperty("Id")){
            memberObject['userId'] = member.Id;
        }
        if(member.hasOwnProperty("AuthorizationEmail__c")){
            memberObject['userEmail'] = member.AuthorizationEmail__c;
        }
        for(var attrib in memberObject){
            try{
                if(typeof(memberObject[attrib]) === "string"){
                    memberObject[attrib] = memberObject[attrib].trim();
                }                
            }catch(e){ }
        }
        return memberObject;
    },
    getRadiusArray(){
        const radiusArray = [1,2,3,4,5,10,15,20,25,35,50,75,100,250];
        return radiusArray;
    },
    getCookies(){
        const cookies = Cookie.getAll();
        return encodeURIComponent(JSON.stringify(cookies));
    },
    getTopics(){
        const topics = ['Food', 'Travel', 'Parenting', 'Fitness'];
        return topics;
    },
    getSettings(loadNew = false){
        return new Promise(function(resolve, reject){
            const settings = localStorage.getItem("mydrhank_settings");
            if(settings !== null && loadNew === false){
                return resolve(JSON.parse(settings));
            }
            const ApiUrl = Config.getApiUrl()+"public/settings";
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers
            }).then(res => {
                if(res.data.numberOfResponses > 0){
                    localStorage.setItem("mydrhank_settings", JSON.stringify(res.data.config));
                    return resolve(res.data.config);
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    sendActivity(requestData, cancelTokenSource = null){
        // //Handle 0 - 10;
        // if(requestData.length <= 10){
        //     return this.sendActivityPartial(requestData, cancelTokenSource);
        // }

        // //Handle 0 - 20;
        // this.sendActivityPartial(requestData.splice(0,10));
        // if(requestData.length <= 10){
        //     return this.sendActivityPartial(requestData, cancelTokenSource);
        // }

        // //Handle 0 - 30;
        // this.sendActivityPartial(requestData.splice(0,10));
        // if(requestData.length <= 10){
        //     return this.sendActivityPartial(requestData, cancelTokenSource);
        // }

        // //Handle 0 - 40;
        // this.sendActivityPartial(requestData.splice(0,10));
        // if(requestData.length <= 10){
        //     return this.sendActivityPartial(requestData, cancelTokenSource);
        // }

        // //Handle 0 - 50;
        // this.sendActivityPartial(requestData.splice(0,10));
        // if(requestData.length <= 10){
        //     return this.sendActivityPartial(requestData, cancelTokenSource);
        // }
    },
    sendActivityPartial(requestData, cancelTokenSource = null){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/activity";
            let headers = Config.getApiHeaders();
            if(cancelTokenSource === null){
                cancelTokenSource = axios.CancelToken.source();
            }
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    sendAdClickedActivity(){
        let sendUrlClicked = false;
        const gclid = helper.getParam("gclid");
        if(gclid && gclid.length > 0 && gclid !== "null"){
            sendUrlClicked = true;
        }
        const fbclid = helper.getParam("fbclid");
        if(fbclid && fbclid.length > 0 && fbclid !== "null"){
            sendUrlClicked = true;
        }
        if(sendUrlClicked){
            var requestData = [];
            requestData.push({
                'activityType': "url_clicked",
                'url': window.location.href,
                'data': Cookie.getAdsData()
            });
            
            this.sendActivity(requestData);
        }
    },
    updateUser(userId, requestData, cancelTokenSource = null){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/user/"+userId;
            let headers = Config.getApiHeaders();
            if(requestData.hasOwnProperty("username")){
                requestData.username = requestData.username.toLowerCase();
            }
            let data = Object.assign({}, requestData);
            if(data.hasOwnProperty("avatarMedia")){
                delete data["avatarMedia"];
            }
            if(data.hasOwnProperty("coverMedia")){
                delete data["coverMedia"];
            }
            if(cancelTokenSource === null){
                cancelTokenSource = axios.CancelToken.source();
            }
            axios.put(ApiUrl, JSON.stringify(data), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    getStates(){
        return [
            { value: "AL", label: "AL" },
            { value: "AK", label: "AK" },
            { value: "AZ", label: "AZ" },
            { value: "AR", label: "AR" },
            { value: "CA", label: "CA" },
            { value: "CO", label: "CO" },
            { value: "CT", label: "CT" },
            { value: "DC", label: "DC" },
            { value: "DE", label: "DE" },
            { value: "FL", label: "FL" },
            { value: "GA", label: "GA" },
            { value: "GU", label: "GU" },
            { value: "HI", label: "HI" },
            { value: "ID", label: "ID" },
            { value: "IL", label: "IL" },
            { value: "IN", label: "IN" },
            { value: "IA", label: "IA" },
            { value: "KS", label: "KS" },
            { value: "KY", label: "KY" },
            { value: "LA", label: "LA" },
            { value: "ME", label: "ME" },
            { value: "MD", label: "MD" },
            { value: "MA", label: "MA" },
            { value: "MI", label: "MI" },
            { value: "MN", label: "MN" },
            { value: "MS", label: "MS" },
            { value: "MO", label: "MO" },
            { value: "MT", label: "MT" },
            { value: "NE", label: "NE" },
            { value: "NV", label: "NV" },
            { value: "NH", label: "NH" },
            { value: "NJ", label: "NJ" },
            { value: "NM", label: "NM" },
            { value: "NY", label: "NY" },
            { value: "NC", label: "NC" },
            { value: "ND", label: "ND" },
            { value: "OH", label: "OH" },
            { value: "OK", label: "OK" },
            { value: "OR", label: "OR" },
            { value: "PA", label: "PA" },
            { value: "PR", label: "PR" },
            { value: "RI", label: "RI" },
            { value: "SC", label: "SC" },
            { value: "SD", label: "SD" },
            { value: "TN", label: "TN" },
            { value: "TX", label: "TX" },
            { value: "UT", label: "UT" },
            { value: "VT", label: "VT" },
            { value: "VA", label: "VA" },
            { value: "VI", label: "VI" },
            { value: "WA", label: "WA" },
            { value: "WV", label: "WV" },
            { value: "WI", label: "WI" },
            { value: "WY", label: "WY" }
        ]
    },
    getStatesList(){
        return [
            "AL",
            "AK",
            "AZ",
            "AR",
            "CA",
            "CO",
            "CT",
            "DC",
            "DE",
            "FL",
            "GA",
            "GU",
            "HI",
            "ID",
            "IL",
            "IN",
            "IA",
            "KS",
            "KY",
            "LA",
            "MA",
            "ME",
            "MD",
            "MI",
            "MN",
            "MS",
            "MO",
            "MT",
            "NE",
            "NV",
            "NH",
            "NJ",
            "NM",
            "NY",
            "NC",
            "ND",
            "OH",
            "OK",
            "OR",
            "PA",
            "PR",
            "RI",
            "SC",
            "SD",
            "TN",
            "TX",
            "UT",
            "VT",
            "VA",
            "VI",
            "WA",
            "WV",
            "WI",
            "WY",
        ]
    },
    getTranslateKeys(){
        return {
            name: "name_x",
            dob: "x_dob",
            medicine: "x_quantity",
            frequency: "x_frequency",
            consent: "x_permission",
            teledoc: "x_teledoc",
            medicineRoutine: "x_medications",
            intercourse: "x_subscription",
            takenBefore: "x_previously_used",
            preferredContactMethod: "preferredContactMethod",
            // contactMethodText: "",
            // contactMethodEmail: "",
            // contactMethodPhone: "",
            medicineType: "x_drug",
            subscriptionType: "x_subscription",
            shippingAddress: "x_phy_address",
            city: "x_phy_city",
            state: "x_phy_state",
            zipCode: "x_phy_zip",
            docName: "x_clinic_name",
            officeLocation: "officeLocation",
            officeState: "x_phy_state",
            officeZip: "x_phy_zip",
            docPhone: "x_phy_phone",
            docContactPermission: "x_permission",
            payment: "x_payment",
            card: "x_card_number",
            cardExpiry: "x_card_exp_date",
            offLabelConsent: "x_conditions",
            patientConsent: "x_conditions",
        };
    },
    tranlateObjectKeys(data){
        const translateKeys = this.getTranslateKeys();
        let newData = {};
        for(var attrib in data){
            try{
                if(translateKeys.hasOwnProperty(attrib)){
                    const newKey = translateKeys[attrib];
                    newData[newKey] = data[attrib];
                    if(typeof(data[attrib]) === "string"){
                        newData[newKey] = data[attrib].trim();
                    }else if(data[attrib] === null){
                        newData[newKey] = "";
                    }
                }else{
                    newData[attrib] = data[attrib];
                }
            }catch(e){ }
        }
        return newData;
    },
    getWorkItems(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/mdh_workitem?"+helper.serialize(requestData);
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.hasOwnProperty("result")){
                    return resolve(res.data);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("message")){
                        reject(err.response.data);
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    getWorkItemDetail(id, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/mdh_workitem/"+id+"?"+helper.serialize(requestData);
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if((res.data.hasOwnProperty("result") && res.data.result.length > 0)){
                    return resolve(res.data.result[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("message")){
                        reject(err.response.data);
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    getStages(){
        return ['Stage 1', 'Stage 2', 'Stage 3', 'Stage 4', 'Stage 5', 'Stage 6'];
    },
    getDispositions(stage){
        const state1Dispositions = [
            "Bad Number",
            "Canada",
            "Deceased",
            "Disconnected",
            "DNC",
            "Prank",
            "Test Call",
            "Wrong Number",
            "Bad TD State",
            "CHUP",
            "Language Barrier",
            "LVM",
            "No Answer",
            "Not Interested",
            "Pricing",
            "PT Action",
            "PT Cancelled",
            "Sent Email",
            "Spanish",
            "Stage 3 Failed",
            "Undefined",
            "Will Call Back"
        ]
        const state2Dispositions = [
            "Bad Number",
            "Deceased",
            "Decline",
            "Disconnected",
            "DNC",
            "No Answer",
            "Wrong Number",
            "CHUP",
            "Language Barrier",
            "LVM",
            "Not Interested",
            "PT Action",
            "Sent Email",
            "Spanish",
            "Undefined",
            "Will Call Back",
        ];
        const state3Dispositions = [
            "Dr Denied",
            "Dr Denied 1",
            "Dr Denied 2",
            "Dr Denied 3",
            "Dr Denied Medical",
            "ES",
            "ESF",
            "Fax Failed",
            "Faxed",
            "PT Action",
            "PT Action 1",
            "PT Action 2",
            "PT Action 3",
            "Received",
            "ReFax",
            "Resent",
            "Send to TD",
            "Send to TD 1",
            "Send to TD 2",
            "Send to TD 3",
            "TD Processing",
            "Undefined",
            "UP Pending",
        ];
        const state4Dispositions = [
            "Future Pay Date",
            "Undefined"];
        const state5Dispositions = [
            "Blacklist",
            "Deceased",
            "Decline",
            "DNC",
            "Reorder",
            "Shipped",
            "Stage 3 Denied",
            "TD Processing",
            "UP Reorder",
            "UP Shipped",
        ];
        let dispositions = {
            "Stage 1": state1Dispositions,
            "Stage 2": state2Dispositions,
            "Stage 3": state3Dispositions,
            "Stage 4": state4Dispositions,
            "Stage 5": state5Dispositions,
        }
        if(dispositions.hasOwnProperty(stage)){
            return dispositions[stage];
        }
        return [];
    },
    createWorkItem(requestData, cancelTokenSource){
        // const that = this;
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/mdh_workitem";
            let headers = Config.getApiHeaders();
            // requestData = that.tranlateObjectKeys(requestData);
            axios.post(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.result.length > 0){
                    resolve(res.data.result[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    updateWorkItem(requestData, cancelTokenSource){
        // const that = this;
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/mdh_workitem";
            let headers = Config.getApiHeaders();
            // requestData = that.tranlateObjectKeys(requestData);
            axios.patch(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.result.length > 0){
                    resolve(res.data.result[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                reject(err);
            });
        });
    },
    deleteWorkItem(id, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/mdh_workitem/"+id;
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                data: JSON.stringify(requestData)
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    prepareWorkitemData(data){
        let odooData = {
            accountId: "",
            leadId: "",
            opportunityId: "",
            origin: "",
            patientNotes: "",
            workItemStatus: "",
            taskId: "",
            lastDispositionChangeDateTime: "",
            firstName: "",
            lastName: "",
            mailingStreet: "",
            mailingZip: "",
            mailingCity: "",
            mailingState: "",
            billingStreet: "",
            billingZip: "",
            billingCity: "",
            billingState: "",
            email: "",
            phone: "",
            alternatePhone: "",
            campaignName: "",
            creditCardExpiration: "",
            creditCardNumber: "",
            creditCardCCV: "",
            birthdate: "",
            drug: "",
            frequency: "",
            lastPrice: "",
            ltv: "",
            payment: "",
            permission: "",
            pharmacy: "",
            physicianStreet: "",
            physicianCity: "",
            physicianEmail: "",
            physicianFax: "",
            physicianFirstName: "",
            physicianLastName: "",
            physicianNPI: "",
            physicianPhone: "",
            physicianState: "",
            physicianZip: "",
            quantity: "",
            refills: "",
            scriptDate: "",
            size: "",
            subscription: "",
            workItemId: "",
            assignedEmail: "",
            stage: "",
            disposition: "",
            createdDate: "",
            lastStageChangeDateTime: "",
            updateDispositionDate: "",
            medicalConditions: "",
            allergies: "",
            selfReportedMedications: "",
            sig: "",
            teledoc: "",
            timeZone: "",
            pricing: "",
            pharmacyNotes: "",
            trackingNumber: "",
            previouslyUsed: "",
            isSoftDeleted: "",
            integrationStatus: "",
            belugaDisposition: "",
            belugaStatus: "",
            belugaLastUpdate: "",
            contactPreference: "",
            legacyLog: "",
            legacyTags: "",
            beluga: "",
            createdDateTime: "",
            tag: "",
        };
        odooData = Object.assign(odooData, data);
        return odooData;
    },
    belugaSubmission(id, requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/beluga/submission/"+id;
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token,
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    },
    createObject(data, cancelTokenSource, objectType = "EAO_Tag__c"){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/sObject";
            let headers = Config.getApiHeaders();
            const requestData = {
                sObject: {
                    attributes: {
                        type: objectType,
                    },
                    ...data,
                },
            };
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.numberOfResults > 0){
                    resolve(res.data.result[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("error")){
                        reject({
                            message: err.response.data.error
                        });
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    getObjects(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/sObject/?"+helper.serialize(requestData);
            let headers = Config.getApiHeaders();
            axios({
                url: ApiUrl,
                method: "GET",
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.hasOwnProperty("result") && typeof(res.data.result) === "object"){
                    return resolve(res.data.result);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("message")){
                        reject(err.response.data);
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    deleteObject(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/sObject/?"+helper.serialize(requestData);
            let headers = Config.getApiHeaders();
            axios.delete(ApiUrl, {
                headers: headers,
                cancelToken: cancelTokenSource.token,
                data: JSON.stringify(requestData)
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("error")){
                        reject({message: err.response.data.error});
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    updateObject(requestData, cancelTokenSource){
        const that = this;
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/sfProxy/sObject";
            let headers = Config.getApiHeaders();
            requestData = that.tranlateObjectKeys(requestData);
            axios.patch(ApiUrl, requestData, {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                if(res.data.result.length > 0){
                    resolve(res.data.result[0]);
                }else{
                    reject("Invalid response from api.");
                }
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("error")){
                        reject({message: err.response.data.error});
                        return;
                    }
                }
                reject(err);
            });
        });
    },
    getSFAuthToken(requestData, cancelTokenSource){
        return new Promise(function(resolve, reject){
            const ApiUrl = Config.getApiUrl()+"data/salesforce/authorization/link";
            let headers = Config.getApiHeaders();
            axios.post(ApiUrl, JSON.stringify(requestData), {
                headers: headers,
                cancelToken: cancelTokenSource.token
            }).then(res => {
                resolve(res.data);
            }).catch(err => {
                if(typeof(err.response) === "object" && typeof(err.response.data) === "object"){
                    if(err.response.data.hasOwnProperty("error")){
                        reject({
                            message: err.response.data.error
                        });
                        return;
                    }
                }
                reject(err);
            });
        });
    },
}

export default ApiHelper;
export { ApiHelper };