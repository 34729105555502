import React from "react";
import Config from "../../../Config";
import { Navigate } from "react-router";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @mui/material/core components
import { connect } from "react-redux";
import { mapStateToProps } from "../../store/reducers/rootReducer";
import { helper } from "../../assets/js/utils/Element";

const SFAuth = (props) => {
    const { authorized } = props.state;
    const code = helper.getParam("code");
    if(code && code !== "null" && code.length > 0){
        localStorage.setItem("sf_auth_code", code);
        if(authorized){
            return <Navigate to={"/salesforce/authorization/link?code="+code} />
        }
    }
    if(!authorized){
        return <Navigate to="/login" />
    }
    return (
        <div>
            <a href={Config.getSFLoginUrl("/salesforce/authorization")}>Salesforce Auth</a>
        </div>
    )
};

SFAuth.propTypes = {
    classes: PropTypes.object,
};

export default connect(mapStateToProps)(SFAuth);
