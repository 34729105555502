import React from 'react'
import className from "classnames";
import Config from "../../../Config";
// import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes } from "react-router-dom";

import Sidebar from "./Sidebar/Sidebar.jsx";
import routes from "../../../routes";
import AdminNavbar from "./Navbars/AdminNavbar.jsx";
import Home from "../pages/Default";
import Login from "../pages/auth/Login";
import Signup from "../pages/auth/Signup";
import SignupVerification from "../pages/auth/SignupVerification";
import Salesforce from "../pages/Salesforce";
import Logout from "../pages/auth/Logout";
// import NotFound from "./NotFound";
import Users from "../pages/Users";
import SFAuth from "../pages/SFAuth";
import SFAuthLink from "../pages/SFAuthLink";

import { styled } from '@mui/material/styles';
import "perfect-scrollbar/css/perfect-scrollbar.css";
import image from "../../assets/img/sidebar-2.jpg";
import { adminStyle, classes } from "../../assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";
const RouterComponentRoot = styled('div')(({ theme }) => adminStyle(theme));
// var ps;

const RouterComponent = class extends React.Component {
    constructor(props) {
        super(props);

        this.store = this.props.store;
        this.history = this.props.history;
        this.mainPanel = React.createRef();
        this.state = {
            mobileOpen: false,
            miniActive: true,
            image: image,
            color: "blue",
            bgColor: "blue",
            hasImage: true,
            fixedClasses: "dropdown",
            logo: Config.getCdnUrl()+"/assets/img/logo.png",
            logoMini: Config.getCdnUrl()+"/assets/favicon/favicon-32x32.png"
        };

        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    }
    componentDidMount() {
        // if (navigator.platform.indexOf("Win") > -1) {
        //     ps = new PerfectScrollbar(this.mainPanel.current, {
        //         suppressScrollX: true,
        //         suppressScrollY: false
        //     });
        //     document.body.style.overflow = "hidden";
        // }
        // window.addEventListener("resize", this.resizeFunction);
    }
    componentWillUnmount() {
        // if (navigator.platform.indexOf("Win") > -1) {
        //     ps.destroy();
        // }
        // window.removeEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.history.location.pathname !== this.props.history.location.pathname) {
            this.mainPanel.current.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }
    sidebarMinimize = () => {
        this.setState({ miniActive: !this.state.miniActive });
    }
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    }
    getActiveRoute = routes => {
        let activeRoute = process.env.REACT_APP_MENU_TITLE;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.pathname === (routes[i].layout + routes[i].path)
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    }
    render() {
        const { history, ...rest } = this.props;
        const { logo, logoMini, miniActive } = this.state;
        const mainPanel =
            classes.mainPanel +
            " " +
            className({
                [classes.mainPanelSidebarMini]: this.state.miniActive,
                [classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf("Win") > -1 && false
            });
        return (
            <RouterComponentRoot className={"main-container "+classes.wrapper} id="global_wrapper">
                <Sidebar
                    routes={routes}
                    logoText={process.env.REACT_APP_MENU_TITLE}
                    logo={(miniActive ? logoMini : logo)}
                    // image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    bgColor={this.state.bgColor}
                    miniActive={miniActive}
                    {...rest}
                />
                <div className={"mainContentPanel "+mainPanel} ref={this.mainPanel}>
                    <AdminNavbar
                        sidebarMinimize={this.sidebarMinimize.bind(this)}
                        miniActive={miniActive}
                        brandText={this.getActiveRoute(routes)}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    <div className={classes.content}>
                        <div id="global_content" className={classes.container}>
                            <Routes>
                                <Route path="/" exact={true} element={<Home history={history} />} />
                                {/* <Route path="/apps" exact={true} element={() => <Home history={history} />} />
                                <Route path="/apps/links" exact={true} element={() => <Home history={history} />} />
                                <Route path="/apps/:brandName" exact={true} element={() => <Home history={history} />} />
                                <Route path="/apps/:brandName/:appName" exact={true} element={() => <Home history={history} />} />
                                <Route path="/apps/:brandName/:appName/workitems" exact={true} element={() => <Home history={history} />} /> */}
                                <Route path="/login" element={<Login history={history} />} />
                                <Route path="/signup" element={<Signup history={this.history} />} />
                                <Route path="/salesforce" element={<Salesforce history={this.history} />} />
                                <Route path="/users" exact={true} element={<Users history={this.history} />} />
                                <Route path="/signup/verification" element={<SignupVerification history={this.history} />} />
                                <Route path="/signup/verification/:email" element={<SignupVerification history={this.history} />} />
                                <Route path="/signup/verification/:email/:code" element={<SignupVerification history={this.history} />} />
                                <Route path="/salesforce/authorization" element={<SFAuth history={history} />} />
                                <Route path="/salesforce/authorization/link" element={<SFAuthLink history={history} />} />
                                <Route path="/logout" element={<Logout history={history} />} />
                                <Route path="*" exact={true} element={<Home history={history} />} />
                            </Routes>
                        </div>
                    </div>
                </div>
            </RouterComponentRoot>
        )
    }
}

export default RouterComponent;
